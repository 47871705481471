<template>
    <div class="avatarbox" @click="onClickComponent">
        <div class="initialsbox flex align-items-center">
            <div>{{initials}}</div>
        </div>
        <div class="my-2 line-height-2">
            <div class="font-bold text-sm">{{fullname}}</div>
            <div class="text-xs">{{rolename}}</div>
        </div>

        <div class="flex align-items-center">
            <i v-if="ismenuopen" class="pi pi-angle-up text-xl"></i>
            <i v-else class="pi pi-angle-down text-xl"></i>
        </div>
        <Menu ref="themenu" :model="menuitems" :popup="true" @show="onMenuShow" @hide="onMenuHide" />
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from  "vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import Menu from 'primevue/menu';
import { UserAccessMask, hasUserAccess } from "@/helper/helper";

const router = useRouter();
const store = useStore();
const themenu = ref();
const ismenuopen = ref(false);

onMounted(() => {
});

const initials = computed( () => {
    return store.getters.profile.fullname?.split(' ').map((n)=>n[0]).join('');
});

const fullname = computed( () => { 
    return store.getters.profile.fullname ?? "";
});
const rolename = computed( () => { 
    return store.getters.rolename;
});

// The menu is constructed so that the items make sense to the user's role and rights
const menuitems = computed( () => { 
    
    // always have the Profile item
    let newItems = [
        {
            label: 'Profile',
            icon: 'pi pi-user',
            to: '/profile',
            command: () => {
                router.push('/profile');
            }
        }
    ];

    // Admins get the special administrative menu items
    if (store.getters.isadmin) {
        newItems.push(
        {
            label: 'Administration',
            items: [
            { label: 'Fleets',
                icon: 'pi pi-th-large',
                to: '/fleets',
                command: () => {
                    router.push('/fleets');
                }
            }, { label: 'Users',
                icon: 'pi pi-users',
                to: '/users',
                command: () => {
                    router.push('/users');
                }
            }, { label: 'System',
                icon: 'pi pi-cog',
                to: '/system',
                command: () => {
                    router.push('/system');
                }
            }
        ]});
    } else {
        newItems.push(
                { label: 'Fleets',
                   icon: 'pi pi-th-large',
                   to: '/fleets',
                   command: () => {
                    router.push('/fleets');
                   }
                }, { label: 'System',
                   icon: 'pi pi-cog',
                   to: '/system',
                   command: () => {
                    router.push('/system');
                   }
                });
    }

    // The menu items for fleet management depend on the selected fleet
    let access_flags = store.getters.selectedfleet?.access_flags ?? 0;

    if (store.getters.isadmin) {
        access_flags = 0xFFFF;
    }

    if (access_flags > 0) {
        let mngmtItems = [
            {
                label: 'Devices',
                icon: 'pi pi-list',
                to: '/devices',
                command: () => {
                    router.push('/devices');
                }
            },
            {
                label: 'Software bundles',
                icon: 'pi pi-file',
                to: '/bundlemgmt/bundles',
                command: () => {
                    router.push('/bundlemgmt/bundles');
                }
            },
            {
                label: 'Deployment plans',
                icon: 'pi pi-cloud-download',
                to: '/plans',
                command: () => {
                    router.push('/plans');
                }
            },
            {
                label: 'Regions',
                icon: 'pi pi-globe',
                to: '/regions',
                command: () => {
                    router.push('/regions');
                }
            },
            {
                label: 'Groups',
                icon: 'pi pi-box',
                to: '/groups',
                command: () => {
                    router.push('/groups');
                }
            },
        ];

        if (hasUserAccess(access_flags, UserAccessMask.ManagePlans)) {
            // deployment management access
            mngmtItems.push();
        }

        if (hasUserAccess(access_flags, UserAccessMask.ManageBundles)) {
            // bundle management access
            mngmtItems.push();
        }

        newItems.push({
            label: 'Fleet management',
            items: mngmtItems
        });
    }

    // always add the separator and the log out item at the end
    newItems = newItems.concat(
        {
            separator:true
        },
        {
            label:'Log Out',
            icon:'pi pi-fw pi-power-off',
            command: () => {
                store.dispatch("logout");
            }
        });

    return newItems;
});

const onClickComponent = (event) => {
    if (themenu.value) themenu.value.toggle(event);
}

const onMenuShow = () => {
    ismenuopen.value = true;
}

const onMenuHide = () => {
    ismenuopen.value = false;
}

</script>

<style lang="stylus" scoped>

.avatarbox
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  gap: 0.2rem
  border-radius: 0.6rem
  box-shadow: 0px 1px 2px 1px rgba(53, 65, 77, 0.2);
  border: 2px solid #829AB1
  box-sizing: border-box
  align-items: stretch
  cursor: pointer
  color: #3E4C59
  background-color: #edf2f6
  div
    padding-left: 0.3rem
    padding-right: 0.3rem
    text-align: left
    box-sizing: content-box

.initialsbox
  border-radius: 0.6rem 0 0 0.6rem
  background-color: #bcccdc
  font-size: 1.2rem


</style>
