import { createStore } from 'vuex'
import router from '../router';
import { findInArrayByPkey, removeFromArrayByPkey, processErrorResponseWithToast, hasUserAccess, UserAccessMask } from '@/helper/helper.js';
import { useRestApi } from "../RESTService";
import { formatUserRole } from "@/helper/helper"

export default createStore({
  state: {
    refreshTask: null,
    tokens: null,
    lasterr: null,
    profile: null,
    selectedfleet: null,
    routeAfterLogin: null, // the route to forward to after login
    uisettings: {lastSelectedFleet: 0},
    ccbundle: 0, // change counter to detect a potential change in a bundle
    ccplan: 0,  // change counter to detect a change in plans
    ccregion: 0,
    ccgroup: 0,
    fleets: [],
    users: [], // list of all account users (only available to admins)
    providers: [],
    account: {
      company: "",
      city: "",
      state_region: "",
      postalcode: "",
      countrycode: "",
      uid: "",
      features: 0
    },
    unassigned_devices_count: 0, // number of unassigned devices in the account,
    account_devices_count: 0    // the total number of device in the account
  },
  getters: {
    accesstoken(state) {
      return state.tokens?.accesstoken ?? "";
    },
    refreshtoken(state) {
      return state.tokens?.refreshtoken ?? "";
    },
    isloggedin(state) {
      let a = state.tokens?.accesstoken ?? "";
      let b = state.tokens?.refreshtoken ?? "";

      if (a.length > 0 && b.length > 0) {
        if (localStorage.getItem('tokens'))
          return true;
      }
      return false;
    },
    isProfileLoaded(state) {
      return (state.profile?.pkey > 0);
    },
    profile(state) {
      return state.profile ?? {pkey:0, accountkey:0, username:'',email:'',phone:'',uid:'',fullname:'',gender:0,lang_code:'',role:0,access_status:0,tcreate:'',created_by:''};
    },
    isadmin(state) {
      return (state.profile?.role > 0);
    },
    rolename(state) {
      return formatUserRole(state.profile?.role ?? 0);
    },
    lasterror(state) {
      let o = { valid:false, severity: "warn", caption:"", message:"", duration:0 };
      if (state.lasterr) {
        o.severity = state.lasterr?.severity ?? "warn";
        o.caption = state.lasterr?.caption ?? "";
        o.message = state.lasterr?.message ?? "";
        o.duration = state.lasterr?.duration ?? 0;
        o.valid = (o.caption.length > 0 || o.message.length > 0);
        if (o.valid && o.duration < 1000) o.duration = 1000;
      }
      return o;
    },
    fleets(state) {
      return state.fleets ?? [];
    },
    accountusers(state) {
      return state.users ?? [];
    },
    fleetusers(state) { // the users of the selected fleet
      return state.selectedfleet?.users ?? [];
    },
    getAccessOfUserInSelectedFleet: (state) => (user_pkey) =>  { // returns the access_flags of the user with the given pkey inside the selected fleet
      if (state.selectedfleet) {
        if (state.selectedfleet.users) {
          for (let u of state.selectedfleet.users) {
            if (u.pkey == user_pkey) return u.access_flags;
          }
        }
      }
      return 0;
    },
    routeAfterLogin(state) {
      return state.routeAfterLogin ?? "";
    },
    selectedfleet(state) {
      return state.selectedfleet; // TODO - change this -> use the item from the array
    },
    nrDevicesInSelectedFleet(state) {
      if (state.selectedfleet) {
        return state.selectedfleet?.devicescount ?? 0;
      }
      return 0;
    },
    lastSelectedFleetPkey(state) { // returns the pkey of the last known selected fleet
      return state.uisettings?.lastSelectedFleet ?? 0;
    },
    bundlesinfleet(state) { // returns the bundles in the selected fleet
      return state.selectedfleet?.bundles ?? [];
    },
    regionsinfleet(state) { // returns the regions in the selected fleet
      return state.selectedfleet?.regions ?? [];
    },
    groupsinfleet(state) { // returns the groups in the selected fleet
      return state.selectedfleet?.groups ?? [];
    },
    deploymentplansinfleet(state) { // returns the deployment plans of the selected fleet
      return state.selectedfleet?.deploymentplans ?? [];
    },
    hasAccessToManageBundles(state) {
      return (state.profile?.role > 0) || hasUserAccess(state.selectedfleet?.access_flags, UserAccessMask.ManageBundles);
    },
    hasAccessToManagePlans(state) {
      return (state.profile?.role > 0) || hasUserAccess(state.selectedfleet?.access_flags, UserAccessMask.ManagePlans);
    },
    hasAccessToManageDeviceAttributes(state) {
      return (state.profile?.role > 0) || hasUserAccess(state.selectedfleet?.access_flags, UserAccessMask.ManageDeviceAttributes);
    },
    hasAccessToMoveDevices(state) {
      return (state.profile?.role > 0) || hasUserAccess(state.selectedfleet?.access_flags, UserAccessMask.MoveDevicesAway);
    },
    hasAccessToManageRegions(state) {
      return (state.profile?.role > 0) || hasUserAccess(state.selectedfleet?.access_flags, UserAccessMask.ManageRegions);
    },
    hasAccessToManageGroups(state) {
      return (state.profile?.role > 0) || hasUserAccess(state.selectedfleet?.access_flags, UserAccessMask.ManageGroups);
    },
    hasAccessDeviceHistory(state) {
      return (state.profile?.role > 0) || hasUserAccess(state.selectedfleet?.access_flags, UserAccessMask.ReadDeviceHistory);
    },
    findBundleByPkey: (state) => (pkey) => {
      if (pkey !== undefined && pkey > 0) {
        // search in the selected fleet first!
        let bundle = findInArrayByPkey(state.selectedfleet?.bundles, pkey);
        if (bundle) return bundle;

        // search in all other fleets
        for (let i = 0; i < state.fleets.length; ++i) {
          if (state.selectedfleet != state.fleets[i]) {
            bundle = findInArrayByPkey(state.fleets[i].bundles, pkey);
            if (bundle) return bundle;
          }
        }
      }
      return null;
    },
    findDeploymentPlanByPkey: (state) => (pkey) => {
      if (pkey !== undefined && pkey > 0) {
        // search in the selected fleet first!
        let plan = findInArrayByPkey(state.selectedfleet?.deploymentplans, pkey);
        if (plan) return plan;

        // search in all other fleets
        for (let i = 0; i < state.fleets.length; ++i) {
          if (state.selectedfleet != state.fleets[i]) {
            plan = findInArrayByPkey(state.fleets[i].deploymentplans, pkey);
            if (plan) return plan;
          }
        }
      }
      return null;
    },
    findAccountUserByPkey: (state) => (pkey) => {
      if (pkey !== undefined && pkey > 0) {
        return findInArrayByPkey(state.users, pkey);
      }
      return null;
    },
    ccbundle(state) {
      return state.ccbundle;
    },
    ccplan(state) {
      return state.ccplan;
    },
    ccregion(state) {
      return state.ccregion;
    },
    ccgroup(state) {
      return state.ccgroup;
    },
    account(state) {
      return state.account;
    },
    unassigned_devices_count(state) {
      return state.unassigned_devices_count;
    },
    account_devices_count(state) {
      return state.account_devices_count;
    },
    account_devices_providers(state) {
      return state.providers;
    }
  },
  mutations: {
    initializeStore(state) 
    {
      let storedtokens = null;
      try {
        let storedTokensStr = localStorage.getItem('tokens');
        if (storedTokensStr?.length > 0) {
          storedtokens = JSON.parse(storedTokensStr);
        }
      } catch (e) {
        // 
      }
      state.tokens = storedtokens;

      let uisettings = {};
      try {
        let sUiSettings = localStorage.getItem('uisettings');
        if (sUiSettings?.length > 0) {
          uisettings = JSON.parse(sUiSettings);
        }
      } catch (e) {
        uisettings = {};
      }
      if (uisettings.lastSelectedFleet === undefined) {
        uisettings.lastSelectedFleet = 0;
      }
      state.uisettings = uisettings;

      if (storedtokens)
      {
          const refreshTask = setInterval(() => this.dispatch('renewToken', state.token), 390000); // every 6,5minutes
          state.refreshTask = refreshTask;// In case you want to cancel this task on logout
          this.dispatch('renewToken');
          this.dispatch("fetchprofile");
      }
    },
    seterror(state, errobj) {
      state.lasterr = errobj;
    },
    setRouteAfterLogin(state, sRoute) {
      state.routeAfterLogin = sRoute;
      //if (sRoute) console.log("next route: " + sRoute)
      //else console.log("next route -> root");
    },
    setprofile(state, response) {
      state.profile = response?.user ?? null;
      state.fleets = response?.fleets ?? [];
      state.users = response?.users ?? [];
      state.providers = response?.providers ?? [];
      state.unassigned_devices_count = response?.unassigned_devices_count ?? 0;
      state.account_devices_count = response?.account_devices_count ?? 0;
      state.account = response?.account ?? null;
    },
    modifyprofileuser(state, modifiedUser) {
      state.profile = {...state.profile.user, ...modifiedUser};
    },
    settokens(state, newtokens)
    {
      if (newtokens) {
        localStorage.setItem('tokens', JSON.stringify(newtokens));
      } else {
        localStorage.removeItem("tokens");
      }
      state.tokens = newtokens;

      // if there is a timeout task, cancel it
      if (state.refreshTask) {
          clearInterval(state.refreshTask);
          state.refreshTask = null;
      }

      if (newtokens) {
        const refreshTask = setInterval(() => this.dispatch('renewToken'), 390000); // every 6,5minutes
        state.refreshTask = refreshTask;// In case you want to cancel this task on logout        
        //this.dispatch('initPerspective');
      }
    },
    clear(state)
    {
      state.lasterr = null;
      state.selectedfleet = null;
      state.fleets = [];
      state.users = [];
      state.profile = null;
      state.ccbundle = 0;
      state.ccplan = 0;
      state.ccregion = 0;
      state.ccgroup = 0;
    },
    selectfleet(state, f) {
      if (f?.pkey > 0) { // f is a fleet object with a pkey
        // take a referece to the instance inside the array
        state.selectedfleet = state.fleets.find(el => { return (f.pkey === el.pkey); });
        this.dispatch('fetchfleetdetails', f.pkey); // update the details for the selected fleet
        let bSave = (state.uisettings.lastSelectedFleet != f.pkey);
        state.uisettings.lastSelectedFleet = f.pkey;
        if (bSave) localStorage.setItem('uisettings', JSON.stringify(state.uisettings));
        return;
      } else if (Number.isFinite(f)) { // this is a just a pkey
        if (f > 0) {
          state.selectedfleet = state.fleets.find(el => { return (f === el.pkey); });
          this.dispatch('fetchfleetdetails', f); // update the details for the selected fleet
          let bSave = (state.uisettings.lastSelectedFleet != f);
          state.uisettings.lastSelectedFleet = f;
          if (bSave) localStorage.setItem('uisettings', JSON.stringify(state.uisettings));
          return;
        }
      }
      state.selectedfleet = null;
    },
    updatefleet(state, f) {
      if (f?.pkey > 0) {
        let targetFleet = state.fleets.find(el => { return (f.pkey === el.pkey); });
        if (targetFleet) {
          Object.assign(targetFleet, f); // assign just the content of f without destroying the instance in fleets (this will merge new/changed attributes)
          if (state.selectedfleet?.pkey == f.pkey) {
            // maybe some details changed - bundles, plans, regions or groups
            state.ccbundle++;
            state.ccplan++;
            state.ccregion++;
            state.ccgroup++;
          }
        }
      }
    },
    addFleet(state, f) {
      state.fleets.push(f);
      if (state.fleets.length == 1) {
        state.selectedfleet = state.fleets[0];
      }
    },
    deleteFleet(state, fleet_pkey) {
      if (fleet_pkey == state.selectedfleet?.pkey) {
        state.selectedfleet = null;
      }
      removeFromArrayByPkey(state.fleets, fleet_pkey);
    },
    renameFleet(state, info) {
      if (info?.pkey > 0 && info?.label?.length > 0) {
        let fleet = findInArrayByPkey(state.fleets, info.pkey);
        if (fleet) {
          fleet.label = info.label;
        }
      }
    },
    updatedfleetpicture(state, fleet_pkey) {
      if (fleet_pkey > 0){
        let fleet = findInArrayByPkey(state.fleets, fleet_pkey);
        if (fleet) {
          if (0 === fleet?.filesize) fleet.filesize = 1; // set the filesize of the fleet picture to something else than 0 to enable downloading the picture
        }
      }
    },
    // this mutation will add a region object to the selected fleet
    addRegion(state, {region, fleet_pkey}) { // the region is an object with pkey and label created via the API.
      if (region?.pkey > 0 && fleet_pkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, fleet_pkey);
        if (fleet) {
          if (fleet.regions?.length > 0) {
            fleet.regions.push(region);
          } else {
            fleet.regions = [region];
          }
        }
      }
    },
    // deletes a region from a fleet
    deleteRegion(state, {region_pkey, fleet_pkey}) {
      if (region_pkey > 0 && fleet_pkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, fleet_pkey);
        removeFromArrayByPkey(fleet?.regions, region_pkey);
      }
    },
    renameRegion(state, info) { // info = {pkey, fleetkey, label}
      if (info?.pkey > 0 && info?.label?.length > 0 && info?.fleetkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, info.fleetkey);
        if (fleet) {
          let region = findInArrayByPkey(fleet.regions, info.pkey);
          if (region) {
            region.label = info.label;
            state.ccregion++; // region details changed
          }
        }
      }
    },
    // this mutation will add a group object to the selected fleet
    addGroup(state, {group, fleet_pkey}) { // the group is an object with pkey and label created via the API.
      if (group?.pkey > 0 && fleet_pkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, fleet_pkey);
        if (fleet) {
          if (fleet.groups?.length > 0) {
            fleet.groups.push(group);
          } else {
            fleet.groups = [group];
          }
        }
      }
    },
    // deletes a group from a fleet
    deleteGroup(state, {group_pkey, fleet_pkey}) {
      if (group_pkey > 0 && fleet_pkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, fleet_pkey);
        removeFromArrayByPkey(fleet?.groups, group_pkey);
      }
    },
    renameGroup(state, info) { // info = {pkey, fleetkey, label}
      if (info?.pkey > 0 && info?.label?.length > 0 && info?.fleetkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, info.fleetkey);
        if (fleet) {
          let group = findInArrayByPkey(fleet.groups, info.pkey);
          if (group) {
            group.label = info.label;
            state.ccgroup++; // group details changed
          }
        }
      }
    },

    // this mutation will add a deployment plan object to the corresponding fleet or update an already existing plan object
    mergeDeploymentPlan(state, dp) { // the dp is a complex object with pkey and label created via the API.
      if (dp?.pkey > 0 && dp?.fleetkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, dp.fleetkey);
        if (fleet) {
          if (Array.isArray(fleet.deploymentplans)) {
            let plan = findInArrayByPkey(fleet.deploymentplans, dp.pkey);
            if (plan) {
              Object.assign(plan, dp); // update the plan - assign just the content of the plan without destroying the instance in fleet.deploymentplans
            } else {
              fleet.deploymentplans.push(dp); // add the plan
            }
          } else {
            fleet.deploymentplans = [dp];
          }
          fleet.deploymentplans.sort((a,b) => b.priority - a.priority);
          state.ccplan++;
        }
      }
    },
    deleteDeploymentPlan(state, {plan_pkey, fleet_pkey}) {
      if (plan_pkey > 0 && fleet_pkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, fleet_pkey);
        removeFromArrayByPkey(fleet?.deploymentplans, plan_pkey);
      }
    },
    mergeBundle(state, bundle) { // adds the bundle to the corresponding fleet and merges it with an already existing bundle with the same pkey
      if (bundle?.pkey > 0 && bundle?.fleetkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, bundle.fleetkey);
        if (fleet) {
          if (Array.isArray(fleet.bundles)) {
            let targetBundle = findInArrayByPkey(fleet.bundles, bundle.pkey);
            if (targetBundle) {
              Object.assign(targetBundle, bundle); // assign just the content of bundle without destroying the instance in fleets.bundles
            } else {
              fleet.bundles.push(bundle);
            }
          } else {
            fleet.bundles = [ bundle ];
          }
          state.ccbundle++; // the bundle details changed
        }
      }
    },
    deleteBundle(state, {bundle_pkey, fleet_pkey}) {
      if (bundle_pkey > 0 && fleet_pkey > 0) {
        let fleet = findInArrayByPkey(state.fleets, fleet_pkey);
        removeFromArrayByPkey(fleet?.bundles, bundle_pkey);
      }
    },
    setAccountUsers(state, usersArray) {
      state.users = usersArray;
    }
  },
  actions: {
    logout (context) { // executes a pre-process and finally the actual logout procedure
      if (context.getters.isloggedin && context.getters.isadmin) {
        const restService = useRestApi();
        restService.postPurge().then(() => {
          context.dispatch("logoutForwardProcedure");
        }).catch(() => {
          context.dispatch("logoutForwardProcedure");
        });
      }
      else
      {
        context.dispatch("logoutForwardProcedure");
      }
    },
    logoutForwardProcedure(context) { // the actual logout procedure
      context.commit("settokens");
      context.commit("clear");
      context.commit("seterror", {severity: "info", caption: "Logout", message: "You have been logged out!", duration: 5000} );
      context.commit("setRouteAfterLogin");
      router.replace('/login'); 
    },
    login(context, cred) {
      const restService = useRestApi();
      restService.login(cred?.username ?? "", cred?.password ?? "", cred?.otp ?? "").then((response) => {
        context.commit("settokens", response);
        this.dispatch("fetchprofile");
        let sRouteTo = context.getters.routeAfterLogin;
        if (sRouteTo.length == 0) sRouteTo = '/';
        router.replace(sRouteTo);
        context.commit("setRouteAfterLogin");
      }).catch (
        (error) => {
          if (error?.response?.status == 401) {
            let msg = error?.response?.data?.error ?? "Login failed";
            context.commit("seterror", {severity: "error", caption: "Login failed, ", message: msg, duration: 7000} );
          } else processErrorResponseWithToast(error, context); }
      );
    },
    renewToken(context) {
      if (context.getters.refreshtoken.length == 0) {
        return;
      }

      const restService = useRestApi();

      restService.refreshToken().then((response) => {
          context.commit("settokens", response);
        })
        .catch(function (error) {
          if (error.response === undefined) {
            //console.error(JSON.stringify(error));
          }
          else {
            //console.warn("failed to renew token: status " + error.response.status + ". " +JSON.stringify(error.response.data));
            if (error.response.status == 401)
            {
              context.commit("settokens"); // clear tokens
            }
          }
        });
    },
    addregion(context, regionname) { // adds a new region to the currently selected fleet
      let fleet_pkey = context.getters.selectedfleet?.pkey ?? 0;
      if (context.getters.isloggedin && fleet_pkey > 0 && regionname.length > 0) {
        const restService = useRestApi();
        restService.createRegion(fleet_pkey, regionname).then((response) => {
          let region = response;
          context.commit('addRegion', {region, fleet_pkey} );
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    deleteregion(context, region_pkey) { // deletes the region from the currently selected fleet
      let fleet_pkey = context.getters.selectedfleet?.pkey ?? 0;
      if (context.getters.isloggedin && fleet_pkey > 0 && region_pkey > 0) {
        const restService = useRestApi();
        restService.deleteRegion(fleet_pkey, region_pkey).then(() => {
          context.commit('deleteRegion', { region_pkey, fleet_pkey} );
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    renameregion(context, info) {
      if (context.getters.isloggedin && info?.pkey > 0 && info?.label?.length > 0 && info.fleetkey > 0) {
        const restService = useRestApi();
        restService.renameRegion(info.fleetkey, info.pkey, info.label).then(() => {
          context.commit('renameRegion', info);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    addgroup(context, groupname) { // adds a new group to the currently selected fleet
      let fleet_pkey = context.getters.selectedfleet?.pkey ?? 0;
      if (context.getters.isloggedin && fleet_pkey > 0 && groupname.length > 0) {
        const restService = useRestApi();
        restService.createGroup(fleet_pkey, groupname).then((response) => {
          let group = response;
          context.commit('addGroup', {group, fleet_pkey} );
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    deletegroup(context, group_pkey) { // deletes the group from the currently selected fleet
      let fleet_pkey = context.getters.selectedfleet?.pkey ?? 0;
      if (context.getters.isloggedin && fleet_pkey > 0 && group_pkey > 0) {
        const restService = useRestApi();
        restService.deleteGroup(fleet_pkey, group_pkey).then(() => {
          context.commit('deleteGroup', { group_pkey, fleet_pkey} );
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    renamegroup(context, info) {
      if (context.getters.isloggedin && info?.pkey > 0 && info?.label?.length > 0 && info.fleetkey > 0) {
        const restService = useRestApi();
        restService.renameGroup(info.fleetkey, info.pkey, info.label).then(() => {
          context.commit('renameGroup', info);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    addfleet(context, fleetname) { // adds a new fleet
      if (context.getters.isloggedin && fleetname.length > 0) {
        const restService = useRestApi();
        restService.createFleet(fleetname).then((response) => {
          context.commit('addFleet', response);
          router.push("/fleet/"+response.pkey); // go to the newly created fleet
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    deletefleet(context, fleet_pkey) {
      if (context.getters.isloggedin && fleet_pkey > 0) {
        const restService = useRestApi();
        restService.deleteFleet(fleet_pkey).then(() => {
          context.commit('deleteFleet', fleet_pkey );
          router.replace("/fleets");
        }).catch((error) => processErrorResponseWithToast(error, context));
      }  
    },
    renamefleet(context, info) { // {pkey, label}
      if (context.getters.isloggedin && info?.pkey > 0 && info?.label?.length > 0) {
        const restService = useRestApi();
        restService.renameFleet(info.pkey, info.label).then(() => {
          context.commit('renameFleet', info);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    fetchfleetdetails(context, fleet_pkey) {
      if (context.getters.isloggedin) {
        const restService = useRestApi();
        restService.getFleetDetails(fleet_pkey).then((response) => {
          context.commit("updatefleet", response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    refreshregions(context) { // get an updated array of regions in the selected fleet
      let fleet_pkey = context.getters.selectedfleet?.pkey ?? 0;
      if (context.getters.isloggedin && fleet_pkey > 0) {
        const restService = useRestApi();
        restService.getFleetRegions(fleet_pkey).then((response) => {
          context.commit("updatefleet", response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    refreshgroups(context) { // get an updated array of groups in the selected fleet
      let fleet_pkey = context.getters.selectedfleet?.pkey ?? 0;
      if (context.getters.isloggedin && fleet_pkey > 0) {
        const restService = useRestApi();
        restService.getFleetGroups(fleet_pkey).then((response) => {
          context.commit("updatefleet", response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    refreshaccountusers(context) { // get an updated array of users in the account
      if (context.getters.isloggedin && context.getters.isadmin) {
        const restService = useRestApi();
        restService.getAccountUsers().then((response) => {
          context.commit("setAccountUsers", response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    refreshFleetUsers(context, fleet_pkey) { // get an updated array of users in the given fleet
      if (context.getters.isloggedin && context.getters.isadmin) {
        const restService = useRestApi();
        restService.getFleetUsers(fleet_pkey).then((response) => {
          context.commit("updatefleet", response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    fetchprofile(context) {
      if (context.getters.isloggedin) {
        const restService = useRestApi();
        restService.getProfile().then((response) => {
          context.commit("setprofile", response);

          // Restore the last selected fleet
          let pklsf = context.getters.lastSelectedFleetPkey;
          if (pklsf > 0 && null == context.getters.selectedfleet) {
            context.commit('selectfleet', pklsf);
          }
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    fetchbundle(context, bundle) {
      if (context.getters.isloggedin && bundle?.pkey > 0, bundle?.fleetkey > 0) {
        const restService = useRestApi();
        restService.getBundleDetails(bundle.pkey, bundle.fleetkey).then((response) => {
          context.commit("mergeBundle", response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    deletebundle(context, bundle) {
      if (context.getters.isloggedin && bundle?.pkey > 0, bundle?.fleetkey > 0) {
        const restService = useRestApi();
        let bundle_pkey = bundle.pkey;
        let fleet_pkey = bundle.fleetkey;
        restService.deleteBundle(bundle_pkey, fleet_pkey).then(() => {
          context.commit('deleteBundle', {bundle_pkey, fleet_pkey} );
          router.replace('/bundlemgmt/bundles');
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    triggerBundleImageGeneration(context, bundle) {
      if (context.getters.isloggedin && bundle?.pkey > 0, bundle?.fleetkey > 0) {
        const restService = useRestApi();
        let bundle_pkey = bundle.pkey;
        let fleet_pkey = bundle.fleetkey;
        restService.triggerBundleImgGeneration(bundle_pkey, fleet_pkey).then(() => {
          context.dispatch("fetchbundle", bundle); // image generated!
        });
      }
    },
    fetchplan(context, plan) {
      if (context.getters.isloggedin && plan?.pkey > 0, plan?.fleetkey > 0) {
        const restService = useRestApi();
        restService.getDeploymentPlanDetails(plan.pkey, plan.fleetkey).then((response) => {
          context.commit('mergeDeploymentPlan', response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    addplan(context, plan) { // add a new plan to the selected fleet
      let fleet_pkey = context.getters.selectedfleet?.pkey ?? 0;
      if (context.getters.isloggedin && fleet_pkey > 0 && plan?.label?.length > 0) {
        const restService = useRestApi();
        restService.createDeploymentPlan(fleet_pkey, plan).then((response) => {
          context.commit('mergeDeploymentPlan', response);
          router.replace('/plans'); // Replace the route to prevent accidentaly re-creation of the same plan
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    updateplan(context, plan) { // update a plan
      if (context.getters.isloggedin && plan?.fleetkey > 0 && plan?.label?.length > 0) {
        const restService = useRestApi();
        restService.updateDeploymentPlan(plan).then((response) => {
          context.commit('mergeDeploymentPlan', response);
          router.push('/plans'); // Go back to plans
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    deleteplan(context, plan) {
      if (context.getters.isloggedin && plan?.pkey > 0, plan?.fleetkey > 0) {
        const restService = useRestApi();
        let plan_pkey = plan.pkey;
        let fleet_pkey = plan.fleetkey;
        restService.deleteDeploymentPlan(fleet_pkey, plan_pkey).then(() => {
          context.commit('deleteDeploymentPlan', {plan_pkey, fleet_pkey} );
          router.replace('/plans');
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    activateplan(context, plan) { // activate a plan
      if (context.getters.isloggedin && plan?.fleetkey > 0 && plan?.pkey > 0) {
        const restService = useRestApi();
        restService.enableDeploymentPlan(plan.fleetkey, plan.pkey, true).then((response) => {
          context.commit('mergeDeploymentPlan', response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    deactivateplan(context, plan) { // activate a plan
      if (context.getters.isloggedin && plan?.fleetkey > 0 && plan?.pkey > 0) {
        const restService = useRestApi();
        restService.enableDeploymentPlan(plan.fleetkey, plan.pkey, false).then((response) => {
          context.commit('mergeDeploymentPlan', response);
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    createNewAccountUser(context, user) { // add a new user to the account
      if (context.getters.isloggedin && context.getters.isadmin) {
        const restService = useRestApi();
        restService.createAccountUser(user).then(() => {
          this.dispatch('refreshaccountusers');
          context.commit("seterror", {severity: "success", caption: "User created", message: "Created user "+user.fullname, duration: 5000} );
          router.replace('/users'); // Replace the route to prevent accidentaly re-creation of the same user
        }).catch((error) => processErrorResponseWithToast(error, context));
      }
    },
    modifyAccountUser(context, user) { // modify the user in this account
      if (context.getters.isloggedin && context.getters.isadmin && user?.pkey > 0) {
        const restService = useRestApi();
        restService.modifyAccountUser(user.pkey, user).then(() => {
          this.dispatch('refreshaccountusers');
          context.commit("seterror", {severity: "success", caption: "Changes saved", message: "The modifications have been saved", duration: 3000} );
        }).catch((error) => {
          this.dispatch('refreshaccountusers');
          processErrorResponseWithToast(error, context);
        });
      }
    },
    modifyProfile(context, modifiedProfile) { // modify the user's own profile
      if (context.getters.isloggedin) {
        const restService = useRestApi();
        restService.modifyProfile(modifiedProfile).then(() => {
          context.commit('modifyprofileuser', modifiedProfile);
          context.commit("seterror", {severity: "success", caption: "Changes saved", message: "The modifications have been saved", duration: 3000} );
        }).catch((error) => {
          processErrorResponseWithToast(error, context);
        });
      }
    },
    deleteAccountUser(context, userpkey) { // delete the account user
      if (context.getters.isloggedin && context.getters.isadmin && userpkey > 0) {
        const restService = useRestApi();
        restService.deleteAccountUser(userpkey).then(() => {
          this.dispatch('refreshaccountusers');
          router.replace('/users'); // Replace the route to prevent a repeated attempt to delete the same user
          context.commit("seterror", {severity: "success", caption: "User deleted", message: "The user has been deleted", duration: 3000} );
        }).catch((error) => {
          this.dispatch('refreshaccountusers');
          processErrorResponseWithToast(error, context);
        });
      }
    },
    resetAccountUserPassword(context, user) { // reset the password of the user - the user must set a new password
      if (context.getters.isloggedin && context.getters.isadmin && user?.pkey > 0) {
        const restService = useRestApi();
        restService.resetAccountUserPassword(user?.pkey).then(() => {
          this.dispatch('refreshaccountusers');
          context.commit("seterror", {severity: "success", caption: "Password reset completed", message: "An e-mail with a new password reset link was sent to "+user?.email, duration: 5000} );
        }).catch((error) => {
          this.dispatch('refreshaccountusers');
          processErrorResponseWithToast(error, context);
        });
      }
    },
    blockAccountUserAccess(context, user) { // disable access of this user
      if (context.getters.isloggedin && context.getters.isadmin && user?.pkey > 0) {
        const restService = useRestApi();
        restService.deactivateAccessAccountUser(user?.pkey).then(() => {
          this.dispatch('refreshaccountusers');
          context.commit("seterror", {severity: "success", caption: "User access disabled", message: user.fullname + " will not be able to login anymore", duration: 5000} );
        }).catch((error) => {
          this.dispatch('refreshaccountusers');
          processErrorResponseWithToast(error, context);
        });
      }
    },
    unblockAccountUserAccess(context, user) { // re-enable access of this user
      if (context.getters.isloggedin && context.getters.isadmin && user?.pkey > 0) {
        const restService = useRestApi();
        restService.activateAccessAccountUser(user?.pkey).then(() => {
          this.dispatch('refreshaccountusers');
          context.commit("seterror", {severity: "success", caption: "User access enabled", message: user.fullname + " will now be able to log in again, if a password is set", duration: 6000} );
        }).catch((error) => {
          this.dispatch('refreshaccountusers');
          processErrorResponseWithToast(error, context);
        });
      }
    },
    setFleetBasedUserAccess(context, {user_pkey, access_flags}) { // sets new access flags to the user for this fleet
      let fleet_pkey = context.getters.selectedfleet?.pkey ?? 0;

      if (context.getters.isloggedin && context.getters.isadmin && user_pkey > 0) {
        const restService = useRestApi();
        restService.modifyFleetAccessOfUser(user_pkey, fleet_pkey, access_flags).then(() => {
          this.dispatch('refreshFleetUsers', fleet_pkey);
        }).catch((error) => {
          this.dispatch('refreshaccountusers');
          processErrorResponseWithToast(error, context);
        });
      }
    },
  },
  modules: {
  }
})
