<template>
  <div class="flex flex-auto">
    <div v-if="hasfleets" class="flex flex-auto gap-3 align-items-center justify-content-center flex-wrap" >
        <FleetCard v-for="f in fleets" :key="f.pkey" :displayfleet="f" />
    </div>
    <div v-else-if="isadmin" class="flex flex-auto text-xl font-bold align-items-center align-content-center justify-content-center" style="margin-bottom:10%;">
        <div style="max-width:30rem">
            Before you can deploy software to any of your products, you have to create a fleet first!
            <br />
            <div class="text-sm font-normal mt-2" style="line-height:1rem">A fleet is a bunch of products that belong together in terms of process organization.
                When products are manufactured, they become a part of a fleet. Different fleets can be managed by different persons.
                Different deployment strategies can be applied on different fleets.</div>
            <br />
            <br />
            Open <router-link to="/fleets">Product fleets</router-link> and create your first fleet
        </div>
    </div>
    <div v-else class="flex flex-auto text-xl font-bold align-items-center align-content-center justify-content-center" style="margin-bottom:10%;">
        <div style="max-width:30rem">
            You have no access to any fleet!<br /><br />Please ask an administrator to give you access to at least one fleet.
        </div>

    </div>
  </div>
</template>

<script setup>

import { computed } from 'vue';
import { useStore } from 'vuex';
import FleetCard from '@/components/FleetCard';

const store = useStore();

const fleets = computed( () => {
  return store.getters.fleets;
});

const hasfleets = computed( () => {
  return (store.getters.fleets.length > 0);
});

const isadmin = computed( () => {
  return store.getters.isadmin;
});


</script>
