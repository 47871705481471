<template>
    <button class="my-button" type="button">
        <slot>
            <div class="flex flex-row align-items-center" style="gap: 0.4rem;">
                <div class="material-icons border-circle theicon">{{buttonIcon}}</div>
                <div class="thelabel hidden md:block">{{props.label||'&nbsp;'}}</div>
            </div>
        </slot>
    </button>
</template>

<script setup>
import { computed } from  "vue";

const props = defineProps({
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
});

const buttonIcon = computed( () => { 
  if (props.icon?.length > 0) return props.icon;
  return "add";
});

</script>


<style lang="stylus" scoped>

.my-button
  background-color: transparent
  border: 0 none
  padding: 0.2rem 1rem 0.2rem 0.4rem
  font-size: 1rem
  transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, background-size 0.1s cubic-bezier(0.64, 0.09, 0.08, 1)
  border-radius: 0.4rem
  .theicon
    color: #ffffff;
    background-color: rgba(63, 81, 181, 0.92)
    font-size: 1.6rem
    padding: 0.2rem
    font-weight: 700
  .thelabel
    font-size: 1rem

.my-button:enabled:hover
  background: rgba(63, 81, 181, 0.92)
  color: #ffffff
  border-color: transparent
  .theicon
    background-color: transparent

.my-button:enabled:active 
  background: rgba(63, 81, 181, 0.68)
  color: #ffffff
  border-color: transparent

@media only screen and (max-width: 768px)
  .my-button:enabled:hover
    background: transparent
    border-color: transparent
    .theicon
      color: #ffffff;
      background-color: rgba(63, 81, 181, 0.92)

  .my-button:enabled:active 
    background: transparent
    color: #ffffff
    border-color: transparent
    .theicon
      background: rgba(63, 81, 181, 0.68)

</style>>
