<template>

    <div class="p-card p-component cursor-pointer cardwidget" @click="onClickCard">
        <div class="p-card-header w-full">
            <div class="card-banner" ref="imgref" v-bind:style="{ 'backgroundImage': `url(${backgroundImg})` }"></div>
        </div>
        <div class="p-card-body">
            <div class="p-card-title single-line-text">{{fleet.label}}</div>
            <div class="p-card-subtitle"><span style="font-size:0.8rem;white-space: nowrap;">UID {{fleet.uid}}</span></div>
        </div>
        <div class="card-footer">
          <div class="flex align-items-center gap-2 flex-wrap">
            <div><span style="font-size: 1.2rem" class="material-icons icon">router</span></div>
            <div>{{fleet.devicescount}} devices</div>
          </div>

        </div>
    </div>


</template>

<script>
import { ref, onMounted, computed } from  "vue";
import { useStore } from 'vuex'
import { useRestApi } from "@/RESTService";
//import { processErrorResponseWithToast } from "@/helper/helper";
//import { timestampStringToDate } from "@/epcommon";

export default {
  name: 'FleetCard',
  components: {  },
  props: {
    displayfleet: {
      type: Object,
      default: () => { return {pkey:0, label: ""} }
    },
    setinstore: {
      type: Boolean,
      default: true
    }
  },
  emits: ['select'],
  setup(props, ctx)
  {
    const store = useStore();
    const restService = useRestApi();
    const imageUrl = ref();
    const imgref = ref();

    onMounted(() => {
      downloadImage();
    });

    const fleet = computed( () => { 
        return props.displayfleet;
    });

    const downloadImage = async () => {
      if (props.displayfleet?.filesize > 0) { // try to download the image. only if it has a positive file size, a file is avialable for download
        await restService.downloadFleetPicture(props.displayfleet.pkey).then((response) => {
          let mimetype = response.headers['content-type'] ?? "image/jpeg";
          const blob = new Blob([response.data], { type: mimetype });
          imageUrl.value = URL.createObjectURL(blob); // urlCreator.createObjectURL(this.response);
        }).catch((error) => {
          console.log(JSON.stringify((error.config?.url ?? "url") + " " + (error.response.statusText ?? '')));
        } );
      }
    }

    const backgroundImg = computed(() => {
        return (imageUrl.value ? imageUrl.value : require("@/assets/placeholder-fleet.webp"));
    });

    const onClickCard = (event) => {
      if (props.setinstore)
      {
        store.commit('selectfleet', props.displayfleet);
      }
      ctx.emit('select', event);
    };

    return { imgref, fleet, backgroundImg, onClickCard };
  }
}
</script>

<style scoped>


</style>
