import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import Button from 'primevue/button';
import Card from 'primevue/card';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import Password from 'primevue/password';
import Ripple from 'primevue/ripple';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import Steps from 'primevue/steps';
import Inplace from 'primevue/inplace';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import Chip from 'primevue/chip';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Tag from 'primevue/tag';
import Panel from 'primevue/panel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Toolbar from 'primevue/toolbar';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import ToggleButton from 'primevue/togglebutton';

// Import the layout perspectives
import PerspectiveAdmin from '@/layouts/PerspectiveAdmin.vue';
import PerspectiveWithSelectedFleet from '@/layouts/PerspectiveWithSelectedFleet.vue';
import PerspectivePlainLogin from '@/layouts/PerspectivePlainLogin.vue';
import ProfileMenu from '@/components/ProfileMenu.vue';
import LogoBox from '@/components/LogoBox.vue';
import FleetSelector from '@/components/FleetSelector.vue';
import AddButton from '@/components/AddButton.vue';
import ActionButton from '@/components/ActionButton.vue';
import IconButton from '@/components/IconButton.vue';
import DeploymentPlanCard from "@/components/DeploymentPlanCard.vue";
import FileUploadEx from "@/components/FileUploadEx.vue";
import TopMenu from "@/components/TopMenu.vue";

//import 'primevue/resources/themes/md-light-indigo/theme.css';   //theme
//import '@/assets/fm-theme.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primevue/resources/primevue.min.css';   //core css
import 'primeicons/primeicons.css';             //icons
import 'primeflex/primeflex.css';
import '@/assets/fleetwarden.css';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(PrimeVue, { ripple: true});
app.use(ConfirmationService);
app.use(ToastService);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);

app.component('PerspectiveAdmin', PerspectiveAdmin);
app.component('PerspectiveWithSelectedFleet', PerspectiveWithSelectedFleet);
app.component('PerspectivePlainLogin', PerspectivePlainLogin);
app.component('ProfileMenu', ProfileMenu);
app.component('LogoBox', LogoBox);
app.component('FleetSelector', FleetSelector);
app.component('AddButton', AddButton);
app.component('ActionButton', ActionButton);
app.component('IconButton', IconButton);
app.component("DeploymentPlanCard", DeploymentPlanCard);
app.component("FileUploadEx", FileUploadEx);
app.component("TopMenu", TopMenu);

app.component('Button', Button);
app.component('Card', Card);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('Password', Password);
app.component('Toast', Toast);
app.component('Steps', Steps);
app.component('Inplace', Inplace);
app.component('Skeleton', Skeleton);
app.component('ProgressSpinner', ProgressSpinner);
app.component('InputSwitch', InputSwitch);
app.component('Calendar', Calendar);
app.component('MultiSelect', MultiSelect);
app.component('Chip', Chip);
app.component('Textarea', Textarea);
app.component("Timeline", Timeline);
app.component("Tag", Tag);
app.component("Panel", Panel);
app.component("TabPanel", TabPanel);
app.component("TabView", TabView);
app.component("Toolbar", Toolbar);
app.component("Badge", Badge);
app.component("ScrollPanel", ScrollPanel);
app.component("SelectButton", SelectButton);
app.component("ToggleButton", ToggleButton);

app.mount('#app');

//app.use(store).use(router).mount('#app')
