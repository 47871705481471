<template>
  <div class="login">
    <div v-if="isloggedin" class="loginbox" style="display: inline-block;font-size: 2rem"><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i> Loading fleets...</div>
    <div v-else class="loginbox grid">
        <div class="col-12 md:col flex align-items-center text-center justify-content-center">
            <div class="mx-6">
                <img alt="Logo" class="mr-3" style="height: 6.0rem;" src="@/assets/birdwings-logo.svg" />
                <div class="mt-2">
                    <span class="text-xl">FLEET</span><br />
                    <span class="text-sm">WARDEN</span>
                </div>
            </div>
        </div>

        <div class="col-12 md:col loginbox-right">
            <div>Login</div>
            <InputText autofocus v-model="m_Username" type="text" @keyup.enter="submitLogin" placeholder="" maxlength="64" />
            <div class="mt-3">Password</div>
            <!--<InputText autofocus v-model="m_Password" type="text" @keyup.enter="submitLogin" placeholder="" maxlength="64" />-->
            <Password v-model="m_Password" :feedback="false" @keyup.enter="submitLogin" maxlength="64" toggleMask />
            <div class="mt-3">One-Time-Password (2nd factor)</div>
            <InputText autofocus v-model="m_OTP" type="text" @keyup.enter="submitLogin" placeholder="" maxlength="8" />

            <div class="flex mt-4 flex-column loginrow">
                <Button label="Log In" class="w-full" @click="submitLogin" />
            </div>
        </div>

    </div>
  </div>
</template>

<script setup>

import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';

const store = useStore();
const router = useRouter();
const m_Password = ref("");
const m_Username = ref("");
const m_OTP = ref("");

onMounted(() => {
  if (store.getters.isloggedin) {
    router.replace('/');
  }
});

const submitLogin = async () =>
{
    if (m_Username.value.length < 1) {
        store.commit("seterror", {severity:"warn", caption : "Missing username", message:"Please enter your username.", duration: 6000} );
    }
    else if (m_Password.value.length < 4) {
        store.commit("seterror", {severity:"warn", caption : "Password too short", message:"Please enter the complete password.", duration: 6000} );
    }
    else {
        let cred = {username: m_Username.value, password: m_Password.value, otp: m_OTP.value};
        store.dispatch("login", cred);
    }
}

const isloggedin = computed( () => {
  return store.getters.isloggedin;
});

</script>

<style scoped>

.login {
    background-image: url('@/assets/abstract-login-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginbox {
  background: linear-gradient(135deg, rgba(255, 255, 255, 40),  rgba(46, 99, 179, 0.25));
  box-shadow: 1px 1px 2px 2px rgba(53, 65, 77, 0.2);
  border-radius: 0.6rem;
  padding: 1.2rem;
  width: fit-content;
}


.loginbox-right {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}



</style>>
