<template>
    <div class="topbar">

        <div class="flex flex-auto justify-content-between align-items-center flex-wrap" style="min-height:3.75rem">
            <div class="flex flex-row">
                <logo-box />

                <div class="flex flex-column align-content-between align-items-start" v-if="isfleetselected || !props.fleetselector" >
                    <div class="fleetbutton" @click="onClickFleet">
                        <span v-if="props.fleetselector">{{selectedFleet.label}}</span>
                        <span v-else>&nbsp;</span>
                    </div>

                    <div class="navbar my-3 md:my-0">
                        <router-link v-for="(link, index) of props.links" :key="index" :to="link.to">
                            <span v-if="!link.adminonly || store.getters.isadmin">
                                {{ link.label }}
                            </span>
                        </router-link>
                    </div>
                </div>

            </div>

            <div v-if="props.fleetselector && !isfleetselected" class="font-bold text-xl">
                Please select a fleet!
            </div>

            <profile-menu />
        </div>
    </div>
  </template>

<script setup>

import { computed} from  "vue";
import { useStore } from 'vuex'

const store = useStore();

const props = defineProps({
    links: {
      type: Array
    },
    fleetselector: {
      type: Boolean,
      default: true
    }
});

const isfleetselected = computed( () => {
  return (store.getters.selectedfleet ? true : false);
});

const selectedFleet = computed( () => {
  return store.getters.selectedfleet ?? {pkey:0, label:""};
});

const onClickFleet = () => {
  store.commit('selectfleet', null);
};
</script>

<style lang="stylus" scoped>

.fleetbutton
  font-size: .875rem
  font-weight: 700
  cursor: pointer
  &:hover
    color: #6377bb
    text-decoration: underline

.navbar
  display: flex
  flex-wrap: wrap
  padding-top: 0.2rem
  padding-bottom: 0.4rem
  gap: 1.2rem
  align-content: flex-end
  font-family: Lato, Helvetica, sans-serif
  font-size: 1.5rem
  a
    text-decoration: none
    color: #626C76
    position: relative
    padding-bottom: 0.3rem
  a:hover
    color: #6377bb
  a:active
    color: #7f92d1
  .router-link-active
    color: #35414d !important
  .router-link-active:before
    content: ""
    position: absolute
    width: 2rem
    height: 1px
    bottom: 0
    left: calc(50% - 1rem)
    border-bottom: 2px solid #3e4c59

</style>
