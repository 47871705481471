<template>
    <button class="icon-button" :title="label" type="button">
        <slot>
            <div class="material-icons theicon">{{buttonIcon}}</div>
        </slot>
    </button>
</template>

<script setup>
import { computed } from  "vue";

const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  icon: {
    type: String,
    default: "settings"
  }
});
  
const buttonIcon = computed( () => { 
  if (props.icon?.length > 0) return props.icon;
  return "settings";
});

</script>


<style lang="stylus" scoped>

.icon-button
  background-color: transparent
  border: 0 none
  padding: 0
  font-size: 1rem
  transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, background-size 0.1s cubic-bezier(0.64, 0.09, 0.08, 1)
  border-radius: 50%;
  color: var(--primary-color)
  .theicon
    font-size: 1.8rem
    padding: 0.25rem
    font-weight: 700

.icon-button:enabled:hover
  background-color: rgba(63, 81, 181, 0.92)
  color: #ffffff
  border-color: transparent
  .theicon
    background-color: transparent

.icon-button:enabled:active 
  background-color: rgba(63, 81, 181, 0.68)
  color: #ffffff
  border-color: transparent

</style>>
