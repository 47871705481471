<template>
    <div class="hidden md:flex mr-4 align-items-center">
        <span class="font-bold text-left" style="margin-right:-1.2rem;line-height:1.10rem;">
            <span class="text-xl">FLEET</span><br />
            <span class="text-sm">WARDEN</span>
        </span>
        <img alt="Logo" class="mr-3" style="height: 3.2rem;" src="@/assets/birdwings-logo.svg">
    </div>
</template>

<script>
export default {

}
</script>

