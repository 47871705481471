<template>
  <div class="app-layout-wrapper" >
    <router-view class="flex-auto" />
  </div>
</template>

<script setup>

</script>

