<template>
  <div class="layout-wrapper">

    <TopMenu :links="toplevellinks" :fleetselector="true" />

    <div class="maincontent ">
      <router-view v-if="store.getters.selectedfleet" class="h-full" />
      <fleet-selector v-else />
    </div>
      
  </div>
</template>

<script setup>

import { ref} from  "vue";
import { useStore } from 'vuex'

const store = useStore();

const toplevellinks = ref([
      {label: "Devices", adminonly:false, to: "/devices"},
      {label: "Bundles", adminonly:false, to: "/bundlemgmt/bundles"},
      {label: "Deployment plans",   adminonly:false, to: "/plans"},
      {label: "Mass configure", adminonly:false, to: "/massconfig"},
      {label: "Regions", adminonly:false, to: "/regions"},
      {label: "Groups",  adminonly:false, to: "/groups"}
]);

</script>
