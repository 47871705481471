<template>
    <div class="flex flex-wrap gap-6" >
        <div class="p-card p-component cardwidget">
            <div class="p-card-header w-full">
                <div class="cardimg1 card-banner flex justify-content-center align-items-center relative" :class="imgClass">
                    <div v-if="iscompleted" class="completedsticker">
                        <span>COMPLETED</span>
                    </div>
                    <div v-else >
                        <span v-if="isactive" class="material-icons bigiconbutton" @click="onClickSetPlanActive(false)">pause_circle</span>
                        <span v-else class="material-icons bigiconbutton" @click="onClickSetPlanActive(true)">play_circle</span>
                    </div>

                    <icon-button label="Modify deployment plan" class="absolute bottom-0 right-0 mx-2 my-1" @click="onClickEditPlan" />
                </div>
            </div>

            <div class="p-card-body ">
                <div class="p-card-title single-line-text">{{plan.label}}</div>
                <div class="p-card-subtitle single-line-text text-sm">
                    {{bundle?.versionstring ?? "no bundle for deployment"}}
                </div>

                <div class="p-card-content">
                    <div class="h-full flex flex-column justify-content-end">
                        <div class="inforow">
                            <div>Effective:</div>
                            <div>{{effectiveLabel}}</div>
                        </div>
                        <div class="inforow">
                            <div>Deployed:</div>
                            <div>{{plan.updatescount}} <span v-if="plan.maxupdates>0">of {{plan.maxupdates}}</span> times</div>
                        </div>
                        <div class="inforow">
                            <div>Targets:</div>
                            <div>{{targetInfo}}</div>
                        </div>
                        <div class="inforow">
                            <div v-if="plan?.maxcoveragerate>0">Attempts {{plan.maxcoveragerate}}&percnt; coverage</div>
                            <div v-else>Attempts max coverage</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer flex flex-row justify-content-between">
                <div class="text-sm single-line-text" style="max-width:12rem">{{statusTxt}}</div>

                <div class="flex align-items-center gap-1 flex-wrap">
                    <div class=""><span style="font-size: 1.0rem" class="material-icons icon">event</span></div>
                    <div class="text-sm">{{formatDate(plan.teffective)}}</div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import { onMounted, computed } from  "vue";
import { useStore } from 'vuex'
import { formatDate, timestampStringToDate } from "@/helper/helper";

export default {
  name: 'DeploymentPlanCard',
  components: {  },
  props: {
    displayplan: {
      type: Object,
      default: () => { return {pkey:0, label: '', teffective:'', completed:0, active:0, bundlekey:0 } }
    }
  },
  emits: ['activateplan', 'deactivateplan', 'editplan'],
  setup(props, ctx)
  {
    const store = useStore();

    const PlanState = {
      Inactive: 0,
      InactiveNoBundle: 1,
      InactiveNotEffective: 2,
      Scheduled: 3,
      Executing: 4,
      Completed: 5
    };

    onMounted(() => {
    });

    const plan = computed( () => { 
      return props.displayplan;
    });

    const bundle = computed( () => {
      return store.getters.findBundleByPkey(plan.value?.bundlekey);
    });

    const iscompleted = computed( () => { 
      let c = props.displayplan?.status ?? 0;
      return (c == 2);
    });

    const isactive = computed( () => { 
      let c = props.displayplan?.status ?? 0;
      return (c == 1);
    });

    const iseffective = computed( () => { 
      let dt = timestampStringToDate(props.displayplan.teffective, true)
      if (dt) {
        return (dt <= new Date());
      }
      return false;
    });

    const effectiveLabel = computed( () => {
      let p = props.displayplan;
      if (p?.tcompletebefore?.length > 0) {
        return formatDate(p.teffective) + " to "+formatDate(p.tcompletebefore);
      }
      else {
        return formatDate(p.teffective) + " to open-end";
      }
    });

    const targetInfo = computed( () => { 
        let nr = plan.value.num_regions ?? 0;
        let ng = plan.value.num_groups ?? 0;
        if (nr > 0 && ng > 0) {
          return ''+nr+' region'+(nr > 0 ? 's':'')+' and '+ng+' group'+(ng > 0 ? 's':'');
        } else if (nr > 0 && ng <= 0) {
          return ''+nr+' region'+(nr > 0 ? 's':'');
        } else if (ng > 0 && nr <= 0) {
          return ''+ng+' group'+(ng > 0 ? 's':'');
        }
        return 'all devices in the fleet';
    });

    const planState = computed( () => {
      if (!iscompleted.value) {
          if (bundle.value?.pkey > 0) {
            if (iseffective.value) {
                if (isactive.value) {
                    return PlanState.Executing;
                } else {
                    return PlanState.Inactive;
                }
            } else {
                if (isactive.value) {
                    return PlanState.Scheduled;
                } else {
                    return PlanState.InactiveNotEffective;
                }
            }
          } else {
            return PlanState.InactiveNoBundle;
          }
      }
      return PlanState.Completed;
    });

    const statusTxt = computed( () => {
      switch(planState.value) {
        case PlanState.Executing: return 'Executing';
        case PlanState.Completed: return 'Completed';
        case PlanState.Scheduled: return 'Scheduled';
        case PlanState.InactiveNoBundle: return 'Inactive (no bundle)';

        case PlanState.Inactive:
        case PlanState.InactiveNotEffective:
        default:
          break;
      }
      return 'Inactive';
    });

    const imgClass = computed( () => {
      let bExecuting = false, bScheduled = false, bCompleted = false, bMissingBundle = false;
      switch(planState.value) {
        case PlanState.Executing:
          bExecuting = true;
          break;
        case PlanState.Completed:
          bCompleted = true;
          break;
        case PlanState.Scheduled:
          bScheduled = true;
          break;
        case PlanState.InactiveNoBundle:
          bMissingBundle = true;
          break;

        case PlanState.Inactive:
        case PlanState.InactiveNotEffective:
        default:
          break;
      }

      return { 'executing': bExecuting, 'scheduled': bScheduled, 'completed': bCompleted, 'nobundle': bMissingBundle};
    });

    const onClickSetPlanActive = (bActivate) => {
      switch(planState.value) {
        case PlanState.Completed: return; // action forbidden in this state
        case PlanState.InactiveNoBundle: return; // action forbidden in this state

        case PlanState.Executing: 
        case PlanState.Scheduled:
        case PlanState.Inactive:
        case PlanState.InactiveNotEffective:
        default:
          break;
      }

      if (bActivate) ctx.emit('activateplan', props.displayplan);
      else ctx.emit('deactivateplan', props.displayplan);
    };

    const onClickEditPlan = () => {
      ctx.emit('editplan', props.displayplan);
    };

    return { plan, bundle, targetInfo, isactive, iscompleted, iseffective, statusTxt, effectiveLabel,
            imgClass, formatDate,
            onClickSetPlanActive, onClickEditPlan };
  }
}
</script>

<style scoped>
.stit {
    color: rgb(52, 102, 114)
}
</style>

<style lang="stylus" scoped>

.cardimg1 {
  background-image: url("@/assets/banner-plan1.webp");
  background-blend-mode: screen
  background-color: rgba(255, 255, 255, 0.6)
}

.cardimg1.completed {
  background-color: #e6e6e6 !important;
  background-blend-mode: overlay !important;
}

.cardimg1.executing {
  background-image: url("@/assets/banner-plan1.webp"), radial-gradient(#AAFFAAFF, #9198e500) !important;
  background-blend-mode: darken !important;
}

.cardimg1.scheduled {
  background-image: url("@/assets/banner-plan1.webp"), radial-gradient(#edcf37, #9198e500) !important;
  background-blend-mode: lighten !important;
}

.cardimg1.nobundle {
  background-image: url("@/assets/banner-plan1.webp"), radial-gradient(#FF0000, #9198e500) !important;
  background-blend-mode: lighten !important;
}

.completedsticker
  background-color: #00000050
  color: #eeeeee
  padding: 0.2rem
  font-size: 1.5rem
  transform: rotate(-10deg)

.bigiconbutton
  user-select: none;
  color: rgb(70, 110, 110)
  font-size: 6rem
  cursor: pointer
  &:hover
    color: rgb(52, 102, 114)
  &:active
    color: rgba(70, 110, 110, 0.9)

</style>
