<template>
    <button class="action-button" type="button" :disabled="props.loading">
        <slot>
            <div class="flex flex-row align-items-center gap-1" >
                <ProgressSpinner v-if="props.loading" class="loading" strokeWidth="4" />
                <div v-else class="material-icons border-circle theicon">{{buttonIcon}}</div>
                <div class="thelabel hidden md:block">
                  <div>{{props.label||'&nbsp;'}}</div>
                  <div v-if="props.loading && props.progress">
                    <ProgressBar :value="props.progress" :showValue="false" style="height: 2px;margin-top: 2px;margin-bottom: 0;"></ProgressBar>
                  </div>
                </div>
            </div>
        </slot>
    </button>
</template>

<script setup>
import { computed } from  "vue";
import ProgressBar from 'primevue/progressbar';

const props = defineProps({
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: undefined
    }
});

const buttonIcon = computed( () => { 
  if (props.icon?.length > 0) return props.icon;
  return "add";
});

</script>


<style lang="stylus" scoped>

.action-button
  background-color: transparent
  border: 0 none
  padding: 0.2rem 1rem 0.2rem 0.4rem
  font-size: 1rem
  transition: background-color 0.1s, border-color 0.1s, color 0.1s, box-shadow 0.1s, background-size 0.1s cubic-bezier(0.64, 0.09, 0.08, 1)
  border-radius: 0.4rem
  color: var(--primary-color)
  .theicon
    font-size: 1.6rem
    padding: 0.2rem
    font-weight: 700
  .thelabel
    font-size: 1rem
  .loading
    font-size: 1rem
    padding: 0.2rem
    width: 1.6rem
    height: 1.6rem

.action-button:enabled:hover
  background: rgba(63, 81, 181, 0.92)
  color: #ffffff
  border-color: transparent
  .theicon
    background-color: transparent

.action-button:enabled:active 
  background: rgba(63, 81, 181, 0.68)
  color: #ffffff
  border-color: transparent

@media only screen and (max-width: 768px)
  .action-button:enabled:hover
    background: transparent
    border-color: transparent
    .theicon
      color: #ffffff;
      background-color: rgba(63, 81, 181, 0.92)

  .action-button:enabled:active 
    background: transparent
    color: #ffffff
    border-color: transparent
    .theicon
      background: rgba(63, 81, 181, 0.68)

</style>>
