<template>
    <div class="p-fileupload p-fileupload-advanced p-component" v-if="isAdvanced">
        <div class="p-fileupload-buttonbar">
            <span class="flex-shrink-0" :class="advancedChooseButtonClass" :style="style" @click="choose" @keydown.enter="choose" @focus="onFocus" @blur="onBlur" v-ripple tabindex="0">
                <input ref="fileInput" type="file" @change="onFileSelect" :multiple="multiple" :accept="accept" :disabled="chooseDisabled" />
                <span :class="advancedChooseIconClass"></span>
                <span class="p-button-label">{{chooseButtonLabel}}</span>
            </span>
            <FileUploadButton class="flex-shrink-0" :label="uploadButtonLabel" :icon="uploadIcon" @click="upload" :disabled="uploadDisabled" v-if="showUploadButton" />
            <FileUploadButton class="flex-shrink-0" :label="cancelButtonLabel" :icon="cancelIcon" @click="clear" :disabled="cancelDisabled" v-if="showCancelButton" />
        </div>
        <div ref="content" class="p-fileupload-content" @dragenter="onDragEnter" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDrop">
            <FileUploadProgressBar :value="progress" :mode="progressbarMode" v-if="isProgressbarVisible" />
            <FileUploadMessage v-for="msg of messages" severity="error" :key="msg" @close="onMessageClose">{{msg}}</FileUploadMessage>
            <div class="p-fileupload-files" v-if="hasFiles">
                <div class="fileupload-row" v-for="(file, index) of files" :key="index">
                    <div v-if="isImage(file)"><img role="presentation" :alt="file.name" :src="file.objectURL" :width="previewWidth" /></div>
                    <div class="p-fileupload-filename">{{file.name}}</div>
                    <div class="flex flex-initial">{{formatSize(file.size)}}</div>
                    <div style="max-width: 24rem;">
                        <Inplace :closable="false" :active="activeinplaceversion">
                            <template #display>Add version</template>
                            <template #content>
                                <InputText v-model="versionstring" class="w-full" placeholder="Version string (cannot be changed later)" maxlength="32" autoFocus />
                            </template>
                        </Inplace>
                    </div>
                    <div>
                        <Inplace :closable="false" :active="activeinplacesignature">
                            <template #display>Add signature</template>
                            <template #content>
                                <InputText v-model="signature" class="w-full" placeholder="Base64 encoded signature" maxlength="65535" autoFocus />
                            </template>
                        </Inplace>
                    </div>
                </div>
            </div>
            <div class="p-fileupload-empty" v-if="$slots.empty && !hasFiles">
                <slot name="empty"></slot>
            </div>
        </div>
    </div>
    <div class="p-fileupload p-fileupload-basic p-component" v-else-if="isBasic">
        <FileUploadMessage v-for="msg of messages" severity="error" :key="msg" @close="onMessageClose">{{msg}}</FileUploadMessage>
        <span :class="basicChooseButtonClass" :style="style" @mouseup="onBasicUploaderClick"  @keydown.enter="choose" @focus="onFocus" @blur="onBlur" v-ripple tabindex="0" >
            <span :class="basicChooseButtonIconClass"></span>
            <span class="p-button-label">{{basicChooseButtonLabel}}</span>
            <input ref="fileInput" type="file" :accept="accept" :disabled="disabled" :multiple="multiple" @change="onFileSelect" @focus="onFocus" @blur="onBlur" v-if="!hasFiles" />
        </span>
    </div>
</template>

<script>
import Button from 'primevue/button';
import ProgressBar from 'primevue/progressbar';
import Message from 'primevue/message';
import {DomHandler} from 'primevue/utils';
import Ripple from 'primevue/ripple';

export default {
    name: 'FileUploadEx',
    emits: ['select', 'uploader', 'before-upload', 'progress', 'upload', 'error', 'before-send', 'clear', 'remove'],
    props: {
        name: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        mode: {
            type: String,
            default: 'advanced'
        },
		multiple: {
			type: Boolean,
			default: false
		},
        accept: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        auto: {
            type: Boolean,
            default: false
        },
        maxFileSize: {
            type: Number,
            default: null
        },
        invalidFileSizeMessage: {
            type: String,
            default: '{0}: Invalid file size, file size should be smaller than {1}.'
        },
        invalidFileTypeMessage: {
            type: String,
            default: '{0}: Invalid file type, allowed file types: {1}.'
        },
        fileLimit: {
            type: Number,
            default: null
        },
        invalidFileLimitMessage: {
            type: String,
            default: 'Maximum number of files exceeded, limit is {0} at most.'
        },
        withCredentials: {
            type: Boolean,
            default: false
        },
        previewWidth: {
            type: Number,
            default: 50
        },
        chooseLabel: {
            type: String,
            default: null
        },
        uploadLabel: {
            type: String,
            default: null
        },
        cancelLabel: {
            type: String,
            default: null
        },
        customUpload: {
            type: Boolean,
            default: false
        },
        showUploadButton: {
            type: Boolean,
            default: true
        },
        showCancelButton: {
            type: Boolean,
            default: true
        },
        chooseIcon: {
            type: String,
            default: 'pi pi-plus'
        },
        uploadIcon: {
            type: String,
            default: 'pi pi-upload'
        },
        cancelIcon: {
            type: String,
            default: 'pi pi-times'
        },
        style: null,
        class: null
    },
    duplicateIEEvent: false,
    data() {
        return {
            uploadedFileCount: 0,
            files: [],
            messages: [],
            focused: false,
            progress: null,
            upstate: 0, // 0 = no upload running, 1 = upload just started, 2 uploaded all bytes
            versionstring: '',
            activeinplaceversion: false,
            signature: '',
            activeinplacesignature: false
        }
    },
    methods: {
        onFileSelect(event) {
            if (event.type !== 'drop' && this.isIE11() && this.duplicateIEEvent) {
                this.duplicateIEEvent = false;
                return;
            }

            this.messages = [];
            this.files = this.files || [];
            let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
            for (let file of files) {
                if (!this.isFileSelected(file)) {
                    if (this.validate(file)) {
                        if (this.isImage(file)) {
                            file.objectURL = window.URL.createObjectURL(file);
                        }
                        this.files.push(file);
                    }
                }
            }

            this.$emit('select', {originalEvent: event, files: this.files});

            if (this.fileLimit) {
                this.checkFileLimit();
            }

            if (this.auto && this.hasFiles && !this.isFileLimitExceeded()) {
                this.upload();
            }

            if (event.type !== 'drop' && this.isIE11()) {
                this.clearIEInput();
            }
            else {
                this.clearInputElement();
            }
        },
        choose() {
            this.$refs.fileInput.click();
        },
        upload() {
            if (this.customUpload) {
                if (this.fileLimit) {
                    this.uploadedFileCount += this.files.length;
                }

                this.$emit('uploader', {files: this.files});
                this.clear()
            }
            else {
                let xhr = new XMLHttpRequest();
                let formData = new FormData();

                this.$emit('before-upload', {
                    'xhr': xhr,
                    'formData': formData
                });

                if (this.versionstring.length > 0) {
                    formData.append('version', this.versionstring);
                }

                if (this.signature.length > 0) {
                    formData.append('signature', this.signature);
                }

                for (let file of this.files) {
                    formData.append(this.name, file, file.name);
                }

                xhr.upload.addEventListener('progress', (event) => {
                    if (event.lengthComputable) {
                        this.progress = Math.round((event.loaded * 100) / event.total);
                        if (this.upstate == 1 && event.loaded >= event.total) {
                            this.upstate = 2;
                        }
                    }

                    this.$emit('progress', {
                        originalEvent: event,
                        progress: this.progress
                    });
                });

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        this.upstate = 0;
                        this.progress = 0;

                        if (xhr.status >= 200 && xhr.status < 300) {
                            if (this.fileLimit) {
                                this.uploadedFileCount += this.files.length;
                            }

                            this.$emit('upload', {
                                xhr: xhr,
                                files: this.files
                            });
                        }
                        else {
                            this.$emit('error', {
                                xhr: xhr,
                                files: this.files
                            });
                        }

                        this.clear();
                    }
                };

                xhr.open('POST', this.url, true);

                this.$emit('before-send', {
                    'xhr': xhr,
                    'formData': formData
                });

                xhr.withCredentials = this.withCredentials;

                this.upstate = 1; // upload just started
                xhr.send(formData);
            }
        },
        clear() {
            this.files = [];
            this.messages = null;
            this.$emit('clear');
            this.versionstring = '';
            this.signature = '';
            this.activeinplaceversion = false;
            this.activeinplacesignature = false;

            if (this.isAdvanced) {
                this.clearInputElement();
            }
        },
        reset() {
            this.files = [];
            this.messages = null;
            this.uploadedFileCount = 0;
            this.versionstring = '';
            this.signature = '';
            this.activeinplaceversion = false;
            this.activeinplacesignature = false;

            if (this.isAdvanced) {
                this.clearInputElement();
            }
        },
        onFocus() {
            this.focused = true;
        },
        onBlur() {
            this.focused = false;
        },
        isFileSelected(file) {
            if (this.files && this.files.length) {
                for (let sFile of this.files) {
                    if ((sFile.name + sFile.type + sFile.size) === (file.name + file.type + file.size))
                        return true;
                }
            }

            return false;
        },
        isIE11() {
            return !!window['MSInputMethodContext'] && !!document['documentMode'];
        },
        validate(file) {
            if (this.accept && !this.isFileTypeValid(file)) {
                this.messages.push(this.invalidFileTypeMessage.replace('{0}', file.name).replace('{1}', this.accept))
                return false;
            }

            if (this.maxFileSize && file.size > this.maxFileSize) {
                this.messages.push(this.invalidFileSizeMessage.replace('{0}', file.name).replace('{1}', this.formatSize(this.maxFileSize)));
                return false;
            }

            return true;
        },
        isFileTypeValid(file) {
            let acceptableTypes = this.accept.split(',').map(type => type.trim());
            for(let type of acceptableTypes) {
                let acceptable = this.isWildcard(type) ? this.getTypeClass(file.type) === this.getTypeClass(type)
                    : file.type == type || this.getFileExtension(file).toLowerCase() === type.toLowerCase();

                if (acceptable) {
                    return true;
                }
            }

            return false;
        },
        getTypeClass(fileType) {
            return fileType.substring(0, fileType.indexOf('/'));
        },
        isWildcard(fileType){
            return fileType.indexOf('*') !== -1;
        },
        getFileExtension(file) {
            return '.' + file.name.split('.').pop();
        },
        isImage(file) {
            return /^image\//.test(file.type);
        },
        onDragEnter(event) {
            if (!this.disabled) {
                event.stopPropagation();
                event.preventDefault();
            }
        },
        onDragOver(event) {
            if (!this.disabled) {
                DomHandler.addClass(this.$refs.content, 'p-fileupload-highlight');
                event.stopPropagation();
                event.preventDefault();
            }
        },
        onDragLeave() {
            if (!this.disabled) {
                DomHandler.removeClass(this.$refs.content, 'p-fileupload-highlight');
            }
        },
        onDrop(event) {
            if (!this.disabled) {
                DomHandler.removeClass(this.$refs.content, 'p-fileupload-highlight');
                event.stopPropagation();
                event.preventDefault();

                const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
                const allowDrop = this.multiple || (files && files.length === 1);

                if (allowDrop) {
                    this.onFileSelect(event);
                }
            }
        },
        onBasicUploaderClick() {
            if (this.hasFiles)
                this.upload();
            else
                this.$refs.fileInput.click();
        },
        remove(index) {
            this.clearInputElement();
            let removedFile = this.files.splice(index, 1)[0];
            this.files = [...this.files];
            this.$emit('remove', {
                file: removedFile,
                files: this.files
            });
        },
        clearInputElement() {
            this.$refs.fileInput.value = '';
        },
        clearIEInput() {
            if (this.$refs.fileInput) {
                this.duplicateIEEvent = true; //IE11 fix to prevent onFileChange trigger again
                this.$refs.fileInput.value = '';
            }
        },
        formatSize(bytes) {
            if (bytes === 0) {
                return '0 B';
            }
            let k = 1000,
            dm = 3,
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        isFileLimitExceeded() {
            if (this.fileLimit && this.fileLimit <= this.files.length + this.uploadedFileCount && this.focused) {
                this.focused = false;
            }

            return this.fileLimit && this.fileLimit < this.files.length + this.uploadedFileCount;
        },
        checkFileLimit() {
            if (this.isFileLimitExceeded()) {
                this.messages.push(this.invalidFileLimitMessage.replace('{0}', this.fileLimit.toString()))
            }
        },
        onMessageClose() {
            this.messages = null;
        }
    },
    computed: {
        isProgressbarVisible() {
            return (this.upstate > 0);
        },
        progressbarMode() {
            if (2 == this.upstate && this.progress >= 100) return 'indeterminate';
            return 'determinate';
        },
        isAdvanced() {
            return this.mode === 'advanced';
        },
        isBasic() {
            return this.mode === 'basic';
        },
        advancedChooseButtonClass() {
            return ['p-button p-component p-fileupload-choose', this.class, {
                    'p-disabled': this.disabled,
                    'p-focus': this.focused
                }
            ];
        },
        basicChooseButtonClass() {
            return ['p-button p-component p-fileupload-choose', this.class, {
                'p-fileupload-choose-selected': this.hasFiles,
                'p-disabled': this.disabled,
                'p-focus': this.focused
            }];
        },
        advancedChooseIconClass() {
            return ['p-button-icon p-button-icon-left pi-fw', this.chooseIcon];
        },
        basicChooseButtonIconClass() {
            return ['p-button-icon p-button-icon-left',
                !this.hasFiles || this.auto ? this.uploadIcon : this.chooseIcon
            ];
        },
        basicChooseButtonLabel() {
            return this.auto ? this.chooseButtonLabel : (this.hasFiles ? this.files.map(f => f.name).join(', ') : this.chooseButtonLabel);
        },
        hasFiles() {
            return this.files && this.files.length > 0;
        },
        chooseDisabled() {
            return this.disabled || (this.fileLimit && this.fileLimit <= this.files.length + this.uploadedFileCount);
        },
        uploadDisabled() {
            return this.disabled || !this.hasFiles || (this.fileLimit && this.fileLimit < this.files.length);
        },
        cancelDisabled() {
            return this.disabled || !this.hasFiles;
        },
        chooseButtonLabel() {
            return this.chooseLabel || this.$primevue.config.locale.choose;
        },
        uploadButtonLabel() {
            return this.uploadLabel || this.$primevue.config.locale.upload;
        },
        cancelButtonLabel() {
            return this.cancelLabel || this.$primevue.config.locale.cancel;
        }
    },
    components: {
        'FileUploadButton': Button,
        'FileUploadProgressBar': ProgressBar,
        'FileUploadMessage': Message
    },
    directives: {
        'ripple': Ripple
    }
}
</script>

<style scoped>
.p-fileupload-buttonbar {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    justify-content: center;
}

.p-fileupload-content {
    position: relative;
}

.fileupload-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
    gap: 1rem;
    justify-content: flex-start;
}

.fileupload-row > div {
    flex: 1 1 auto;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    min-width: 120px;
}

.p-fileupload-content .p-progressbar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.p-button.p-fileupload-choose {
    position: relative;
    overflow: hidden;
}

.p-button.p-fileupload-choose input[type=file] {
    display: none;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type=file] {
    display: none;
}

.p-fileupload-filename {
    word-break: break-all;
}

.p-fluid .p-fileupload .p-button {
    width: auto;
}

.p-inplace-display {
    display: inline-block !important;
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-offset: 0.2em;
}
</style>
