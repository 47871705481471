<template>
  <div class="app-layout-wrapper">
  <!--
    <component v-if="isloggedin && isProfileLoaded" :is="perspectiveLayout" />
    <LoginView v-else class="flex-auto" />
  -->
    <component :is="perspectiveLayout" />
    <ConfirmDialog :closable="false" />
    <Toast />
  </div>
</template>

<script>
import { ref, onMounted, watch, computed} from  "vue";
import { useStore } from 'vuex'
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import Button from 'primevue/button';
import { useRoute, useRouter} from 'vue-router';
import ConfirmDialog from 'primevue/confirmdialog';
import LoginView from "./views/LoginView.vue";
//import PasswordReset from '@/components/PasswordReset.vue'


export default ({
    name: "App",
    components: {
        Toast, Button, ConfirmDialog, LoginView
    },
    beforeCreate()
    {
      this.$store.commit("initializeStore");
    },
    setup() {


        const preferredLayout = ref('');
        const store = useStore();
        const toast = useToast();
        const router = useRouter();
        const route = useRoute();
        //const sidebarMenuVisible = ref(false);

        /*
        router.beforeEach((to from) => {
          if (to?.path == '/pwreset')
          {
            if (to?.query?.secret?.length > 0) {
              store.dispatch("enterPasswordResetMode", to.query.secret);
            } else {
              store.commit('setpassresetmode');
            }
            return false;
          }
          else {
            store.commit('setpassresetmode');
          }
          return true;
        });

        router.afterEach((to, from) => {
          //sendToAnalytics(to.fullPath)
          sidebarMenuVisible.value = false; // close the navigation bar
        })
        */


        router.beforeEach((to /*, from*/) => {
          const persp = to?.meta?.perspective ?? '';
          if (persp !== 'perspective-plain-login') {
            if (!store.getters.isloggedin) {
              //console.log("LOGIN required");
              store.commit("setRouteAfterLogin", to.path);
              router.replace("/login");
            }
          }
          else {
            //console.log("perspective is ok");
          }
          return true;
        });

        watch(() => store.getters.isloggedin, function () {
          updateMenu();
        });

        watch( () => route.meta,
          /*
          async meta => {
            try {
              const component = await import(`@/layouts/${meta.layout}.vue`)
              layout.value = component?.default || AppLayoutDefault
            } catch (e) {
              layout.value = AppLayoutDefault
            }
          }*/
          function () {
            preferredLayout.value = route.meta?.perspective ?? ''; // layout changed
            //console.log("Preferred layout is now: " + preferredLayout.value);
          },
          { immediate: true }
        );

        const perspectiveLayout = computed( () => {
          if (preferredLayout.value?.length > 0) return preferredLayout.value;
          return "perspective-admin";
        });

        const isloggedin = computed( () => {
          return store.getters.isloggedin;
        });

        const isProfileLoaded = computed( () => {
          return store.getters.isProfileLoaded;
        });

        const updateMenu = () => {
          //menuitems.value = [];
          if (store.getters.isloggedin)
          {
            //if (store.getters.isadmin) menuitems.value = adminMenu;
            //else menuitems.value = pouMenu;
          }
        }

        onMounted(() => {
          updateMenu();
        });

        watch(() => store.getters.lasterror, function () {
            let e = store.getters.lasterror;
            if (e.valid)
            {
                toast.add({severity: e.severity, 
                        summary: e.caption,
                        detail: e.message,
                        life: e.duration });
            }
        });

        const onClickLogout = async () => {
            store.dispatch("logout");
        }

        return {isloggedin, isProfileLoaded, onClickLogout, perspectiveLayout };
    },
})
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  margin-top 60px

</style>
