<template>
  <div class="layout-wrapper" >

    <TopMenu :links="toplevellinks" :fleetselector="false" />

    <div class="maincontent">
        <router-view class="w-full h-full" />
    </div>
      
  </div>
</template>

<script setup>

import { ref} from  "vue";

const toplevellinks = ref([
      {label: "Fleets", adminonly:false, to: "/fleets"},
      {label: "Users",  adminonly:true,  to: "/users"},
      {label: "System", adminonly:false, to: "/system"}
]);

</script>
