import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/devices'
  },
  {
    path: '/passreset/:sec', 
    name: "pwreset",
    component: () => import('@/views/ResetPasswordView'),
    meta : { perspective: 'perspective-plain-login' },
    props : (route) => {
      const secret = route.params.sec ?? "";
      return { secret };
    }
  },
  {
    path: '/login', 
    meta : { perspective: 'perspective-plain-login' },
    children: [
      {
        path: '',
        name: "login",
        component: () => import('@/views/LoginView'),
        meta : { perspective: 'perspective-plain-login' }
      }
    ]
  },
  {
    path: '/plans', 
    meta : { perspective: 'perspective-with-selected-fleet' },
    children: [
      {
        path: '',
        name: "deploymentplans",
        component: () => import('@/views/DeploymentPlansView'),
        meta : { perspective: 'perspective-with-selected-fleet' }
      },
      {
        path: 'new', 
        name: "deploymentnewplan",
        component: () => import('@/views/DeploymentPlanView'),
        meta : { perspective: 'perspective-with-selected-fleet' }
      },
      {
        path: 'edit/:pkey', 
        name: "editdeploymentplan",
        component: () => import('@/views/DeploymentPlanView'),
        meta : { perspective: 'perspective-with-selected-fleet' },
        props : (route) => {
          const plankey = Number.parseInt(route.params.pkey, 10)
          if (Number.isNaN(plankey)) {
            return -1;
          }
          return { plankey };
        }
      }
    ]
  },
  {
    path: '/groups', 
    name: "deploymentgroups",
    component: () => import('@/views/DeploymentGroupsView'),
    meta : { perspective: 'perspective-with-selected-fleet' }
  },
  {
    path: '/regions', 
    name: "deploymentregions",
    component: () => import('@/views/DeploymentRegionsView'),
    meta : { perspective: 'perspective-with-selected-fleet' }
  },
  {
    path: '/massconfig', 
    name: "massconfig",
    component: () => import('@/views/MassConfigView'),
    meta : { perspective: 'perspective-with-selected-fleet' }
  },
  {
    path: '/devices', 
    name: "fleetdevices",
    component: () => import('@/views/DevicesView'),
    meta : { perspective: 'perspective-with-selected-fleet' }
  },
  {
    path: '/devicesofregion/:regionpkey',
    name: "devicesbyregion",
    component: () => import('@/views/DevicesView'),
    meta : { perspective: 'perspective-with-selected-fleet' },
    props : (route) => {
      const region_pkey = Number.parseInt(route.params.regionpkey, 10)
      if (Number.isNaN(region_pkey)) {
        return -1;
      }
      return { region_pkey };
    }
  },
  {
    path: '/devicesofbundle/:versionstring',
    name: "devicesbybundle",
    component: () => import('@/views/DevicesView'),
    meta : { perspective: 'perspective-with-selected-fleet' },
    props : (route) => {
      const bundleversion = route.params.versionstring;
      return { bundleversion };
    }
  },
  {
    path: '/devicesofgroup/:grouppkey',
    name: "devicesbygroup",
    component: () => import('@/views/DevicesView'),
    meta : { perspective: 'perspective-with-selected-fleet' },
    props : (route) => {
      const group_pkey = Number.parseInt(route.params.grouppkey, 10)
      if (Number.isNaN(group_pkey)) {
        return -1;
      }
      return { group_pkey };
    }
  },
  {
    path: '/device/:devicekey',
    name: "devicedetails",
    component: () => import('@/views/DeviceDetailsView'),
    meta : { perspective: 'perspective-with-selected-fleet' },
    props : (route) => {
      const devicekey = Number.parseInt(route.params.devicekey, 10)
      if (Number.isNaN(devicekey)) {
        return -1;
      }
      return { devicekey };
    }
  },
  {
    path: '/bundlemgmt/',
    name: 'bundlemgmt',
    children: [
      {
        path: 'bundles',
        name: "bundleslist",
        component: () => import('@/views/BundlesListView'),
        meta : { perspective: 'perspective-with-selected-fleet' }
      },
      {
        path: 'bundle/',
        meta : { perspective: 'perspective-with-selected-fleet' },
        children: [
          {
            path: '',
            name: "bundles",
            component: () => import('@/views/BundlesListView'),
            meta : { perspective: 'perspective-with-selected-fleet' }
          },
          {
            path: ':pkey',
            name: "bundlebypkey",
            component: () => import('@/views/BundleDetailsView'),
            meta : { perspective: 'perspective-with-selected-fleet' },
            props : (route) => {
              const bundle_pkey = Number.parseInt(route.params.pkey, 10)
              if (Number.isNaN(bundle_pkey)) {
                return -1;
              }
              return { bundle_pkey };
            }
          }
        ]
      },
      {
        path: 'upload',
        name: "bundleupload",
        component: () => import('@/views/BundlesUploadView'),
        meta : { perspective: 'perspective-with-selected-fleet' }
      },
    ]
  },
  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
        // webpackChunkName: "about"
        '../views/AboutView.vue')
  },*/
  /*{
    path: "/newbiz",
    component: () => import('@/views/SetupNewBusinessView'),
    children: [
      { path: '', name: "step1", component: NewBizStepBasicInfo },
      { path: 'regions', name: "step2", component: () => import('../components/NewBizStepRegions.vue') },
    ]   
  },*/
  {
    path: "/fleets",
    component: () => import('@/views/AdminFleetsView'),
    meta: {
      perspective: 'perspective-admin'
    },
  },
  {
    path: '/fleet/:pkey', 
    name: "fleet",
    component: () => import('@/views/FleetDetailsView'),
    meta : { perspective: 'perspective-admin' },
    props : (route) => {
      const fleet_pkey = Number.parseInt(route.params.pkey, 10)
      if (!Number.isFinite(fleet_pkey)) {
        return 0;
      }
      return { fleet_pkey };
    }
  },
  {
    path: "/users",
    name: "users",
    component: () => import('@/views/AdminUsersView'),
    meta: {
      perspective: 'perspective-admin'
    }
  },
  {
    path: "/users/new",
    name: "createuser",
    component: () => import('@/views/UserCreateView'),
    meta: {
      perspective: 'perspective-admin'
    }
  },
  {
    path: '/user/:pkey', 
    name: "user",
    component: () => import('@/views/UserDetailsView'),
    meta : { perspective: 'perspective-admin' },
    props : (route) => {
      const user_pkey = Number.parseInt(route.params.pkey, 10)
      if (!Number.isFinite(user_pkey)) {
        return 0;
      }
      return { user_pkey };
    }
  },
  {
    path: "/system",
    component: () => import('@/views/AdminSystemView'),
    meta: {
      perspective: 'perspective-admin'
    }
  },
  {
    path: "/profile",
    component: () => import('@/views/ProfileView'),
    meta: {
      perspective: 'perspective-admin'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
